import React from "react";

import { generateImageMediaUrl } from "../../utils/generateMediaUrl";

import "../../assets/styles/containers/servicespage/types.scss";

export default function Types({ block }) {
  return (
    <div className="service-types">
        <div className="service-types-info">
          <h2 className="service-types-info-title">{block?.title}</h2>
          <h4
            className="service-types-info-description"
            dangerouslySetInnerHTML={{ __html: block?.description }}
          />
        </div>
        <div className="service-types-image">
            {/*<div className="service-types-header">*/}
            {/*    {block?.subTitle && <p className="service-types-name">{block?.subTitle}</p>}*/}
            {/*</div>*/}
          <div className="service-types-image-relative">
            <img
              src={generateImageMediaUrl(block?.mediaMain?.path)}
              alt={block?.mediaMain?.name}
              title={block?.mediaMain?.name}
            />
          </div>
        </div>
    </div>
  );
}
