import React from "react";
import { Link } from "react-router-dom";

import { getUrlWithLocal } from "../../../utils/getUrlWithLocal";

import './LinkButton.scss'

export default function LinkButton({ text, link }) {
  return <Link className='linkButton' to={getUrlWithLocal("/" + link)}>{text}</Link>;
}
