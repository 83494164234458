import {
    request,
    _urlStaticTexts,
    _urlPartners,
    _urlLanguage,
    _urlSubscribers,
    _urlMenu,
    _urlBlogs,
    _urlCountryCode1, _urlHome, _urlSponsors,
} from "../api";
import {GENERAL_CONSTS, UTIL_CONSTS} from "../constants";

export const getBrowserLanguage = () => {
    return navigator.language.split('-')[0] || null;
};

const _GetCountryCode1 = () => {
    const requestData = {
        url: _urlCountryCode1,
        method: "GET",
    };
    return request(requestData)
        .then((res) => {
            const data = res && res.data;
            const code = data?.countryCode === 'AM' ? 'HY' : data?.countryCode
            return code?.toLowerCase() || 'HY'
        }).catch(_ => 'en')
};


export const GetLanguages = () => {
    const requestData = {
        url: `${_urlLanguage}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const countryCode = await _GetCountryCode1()
               // const countryCode = await _GetCountryCode2()
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload:data
                })
            })
    };
};

export const GetStaticTexts = () => {
    const requestData = {
        url: `${_urlStaticTexts}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_STATIC_TEXTS,
                    payload: data
                })
            })
    };
};

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};


export const GetMenu = () => {
    const requestData = {
        url: `${_urlMenu}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_MENU,
                    payload: data
                });
            })
    };
};


export const GetPartners = () => {
    const requestData = {
        url: `${_urlPartners}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_PARTNERS,
                    payload: data
                })
            })
    };
};

export const GetBlogs = ({
                             reset = true,
                             offset = 2,
                             category,
                             limit = 6,
                         } = {}) => {
    let url = `${_urlBlogs}?offset=${offset}&limit=${limit}`;
    category && (url += `&category=${category}`);
    const requestData = {
        url,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_BLOGS,
                    payload: {
                        data,
                        reset,
                        hasMore: data.length === limit,
                    }
                })
            })
    };
};

export const GetLastBlogs = ({
                                 reset = true,
                                 offset = 0,
                                 category,
                                 limit = 3,
                             } = {}) => {
    let url = `${_urlBlogs}?offset=${offset}&limit=${limit}`;
    category && (url += `&category=${category}`);
    const requestData = {
        url,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_LAST_NEWS,
                    payload: {
                        data,
                        reset,
                        hasMore: data.length === limit,
                    }
                })
            })
    };
};

export const sendSubscribe = (data) => {
    const requestData = {
        url: `${_urlSubscribers}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};

export const GetHome = () => {
    const requestData = {
        url: `${_urlHome}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_HOME,
                    payload: data
                })
            })
    };
};
export const GetSponsors = () => {
    const requestData = {
        url: `${_urlSponsors}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SPONSORS,
                    payload: data
                })
            })
    };
};