import {MaintenanceBg, MaintenanceImg, MoreArrIcon, ResponsiveImg} from "../../assets/images";
import "../../assets/styles/homepage/maintenance.scss"


function Maintenance(props) {
    const {staticTexts} = props

    return <div className={'maintenance-block'}>
        <div className={'maintenance-wrapper'}>
            <div className={'maintenance-info'}>
                <div className={'maintenance-link'}>
                    <span>{staticTexts?.homepage_maintenance_subtitle}</span> <MoreArrIcon/>
                </div>
                <div className={'maintenance-title'}>
                    {staticTexts?.homepage_maintenance_title}
                </div>
                <div className={'maintenance-description'}>
                    {staticTexts?.homepage_maintenance_description}
                </div>
            </div>
            <div className={'maintenance-img'}>
                <div className={'maintenanceBg'}>
                    <img src={MaintenanceBg} alt="" />
                </div>
                <div className={'responsive-img'}>
                    <img src={ResponsiveImg} alt=""/>
                </div>
                <div className={'img-wrapper'}>
                    <img src={MaintenanceImg} alt=""/>
                </div>
            </div>
        </div>
    </div>

}export default (Maintenance)