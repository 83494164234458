import React from "react";

import { generateImageMediaUrl } from "../../utils/generateMediaUrl";
import LinkButton from "../uiElements/LinkButton/LinkButton";

import "../../assets/styles/containers/servicespage/mainBlock.scss";

export default function MainBlock({ block, staticTexts }) {
  return (
    <div className="service-main-block">
      <div className="service-main-block-img">
        <div className="service-main-block-img-relative">
          <img
            src={generateImageMediaUrl(block?.mediaMain?.path)}
            alt={block?.mediaMain?.name}
            title={block?.mediaMain?.name}
          />
        </div>
      </div>
      <div className="service-main-block-info">
        {/*<p className="service-main-block-info-name">*/}
        {/*  {staticTexts?.service_mainBlock_title}*/}
        {/*</p>*/}
        <h2 className="service-main-block-info-title">{block?.title}</h2>
        <h4
          className="service-main-block-info-description"
          dangerouslySetInnerHTML={{ __html: block?.description }}
        />
        <LinkButton text="Դիմեք մեզ" link="contacts" />
      </div>
    </div>
  );
}
