// import packages
import React, {Component, useEffect, useRef, useState} from "react";
import Slider from "react-slick";

//Import styles
import "../assets/styles/homepage/partners.scss";
import {SliderNextButton, SliderPrevButton} from "./uiElements/buttons";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";

//Import Components

// Import utils


function Partners(props){
    const {data,staticTexts, partners} = props
    const [slideIndex, setSlideIndex] = useState(0);

    const nextSlide = (index) => {
        setSlideIndex(slideIndex - 1 === index ? 0 : index + 1)
        if (data?.length - 1 === slideIndex) {
            setSlideIndex(slideIndex)
        }
        sliderRef.slickNext();
    };

    const prevSlide = (index) => {
        setSlideIndex(index === 0 ? 0 : index - 1)
        sliderRef.slickPrev();
    };

    let sliderRef = useRef(null);
    const settings = {
        dots: false,
        arrows: false,
        autoplay:true,
        infinite:true,
        speed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode:  window.innerWidth < 700,
        centerPadding: '60px 0 0',
        ref: partner => (sliderRef = partner),
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '60px 0 60px ',
                }
            },
        ]
    };

    return !!partners?.length && <div className="partners-wrapper">
        <div className="partners-title">{staticTexts?.homepage_partners_block_title}</div>

        <div className="partners-list-wrapper">
            {
                <Slider {...settings}>
                    {partners?.map(item => {
                        return <div  key={item}>
                            <div className="partner-item" key={item.id}>
                                <div className="partner-content">
                                    <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="fade-image"
                                         title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                         alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                </div>
                            </div>
                        </div>
                    })}
                </Slider>
            }
            {partners?.length > 4 && <>
                <SliderPrevButton  cbPrev={() => prevSlide(slideIndex)}/>
                    <SliderNextButton  cbNext={() => nextSlide(slideIndex)}/>
            </>}
        </div>
    </div>
}
export default Partners