import React from "react";
import "../../assets/styles/components/uiElements/loading-linear.scss";
import {LoadingIcon} from "../../assets/images";

export function LoadingLinear(props) {
    return <div className="linear-loading">
        <div className={'loading-icon'}>
            <LoadingIcon/>
        </div>
    </div>
}
