// import packages
import React, {useState} from "react";
import {connect} from 'react-redux';
import isEmail from "validator/es/lib/isEmail";

//Import assets
import '../assets/styles/containers/contacts.scss';
import {CallIcon,LocationIcon,SMSIcon} from "../assets/images";
import {LoadingOutlined} from "@ant-design/icons";

//Import Components
import {InputGroup} from "../components/uiElements/inputGroup";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {SendEmail} from "../redux/actions";
import {FullscreenControl, Map, Placemark, TypeSelector, YMaps} from "@pbe/react-yandex-maps";
import Subscribe from "../components/Subscribe";
import {useEffect} from "react";


function Contact(props) {
    const {contacts,requestLoading,staticTexts} = props
    const [fieldsData,setFieldsData] = useState({
        firstName:'',
        lastName:'',
        email:'',
        phone:'',
        message:'',
    })
    const [error,setError] = useState({
        firstName:false,
        lastName:false,
        email:false,
        phone:false,
        message:false,
    })

    const mapData = {
        center: [contacts?.latitude, contacts?.longitude],
        zoom: 16,
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && fieldsData.phone.length < 5 )){
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    function sendVisit() {
        if(validate()){
            props.SendEmail(fieldsData).then(() => {
            }).finally(() => {
                setFieldsData({
                    firstName:'',
                    lastName:'',
                    email:'',
                    phone:'',
                    message:'',
                })
            })
        }
    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }
    return <div className={'contact-wrapper'}>
        <div className={'top-block'}>
            <div className={'info-block'}>
                <div className={'info-title'}>
                    {staticTexts?.contact_info_title}
                </div>
                <div className={'email-block'}>
                    <span className={'blocks-title'}> <SMSIcon/> {staticTexts?.contact_email_title}</span>
                    <a href={`mailto:${contacts?.email}`} className={'information'}>
                        {contacts?.email}
                    </a>
                </div>
                <div className={'number-block'}>
                    <span className={'blocks-title'}> <CallIcon/> {staticTexts?.contact_number_title}</span>
                    <a href={`tel:${contacts?.overallNumber}`} className={'information'}>
                        <span>{staticTexts?.contact_first_number}</span> {contacts?.overallNumber}
                    </a>
                    <a href={`tel:${contacts?.salesNumber}`} className={'information'}>
                        <span>{staticTexts?.contact_second_number}</span> {contacts?.salesNumber}
                    </a>
                    <a href={`tel:${contacts?.maintenanceNumber}`} className={'information'}>
                        <span>{staticTexts?.contact_third_number}</span> {contacts?.maintenanceNumber}
                    </a>
                </div>
                <div className={'address-block'}>
                    <span className={'blocks-title'}> <LocationIcon/> {staticTexts?.contact_address_title}</span>
                    <div className={'information'}>
                        {contacts?.address}
                    </div>
                </div>
            </div>
            <div className={'contact-map-block'}>
                <YMaps query={{
                    apikey: '83841bdc-892a-431f-a9ff-b378ef38c6a0',
                    lang: 'en'
                }}>
                    <Map defaultState={mapData} cursors={"inherit"} className={'yandexMap'}>
                        <Placemark
                            geometry={[contacts?.latitude, contacts?.longitude]}
                            classname={'customIcon'}
                        />
                        <FullscreenControl options={{
                            position: {
                                bottom: 28,
                                right: 26,
                            },
                        }}/>
                        <TypeSelector options={{
                            float: 'right'
                        }}/>
                    </Map>
                </YMaps>
            </div>
        </div>
        <div className={'bottom-block'}>
            <div className={'inputs-subTitle'}>
                {staticTexts?.contact_subtitle}
            </div>
            <div className={'inputs-title'}>
                {staticTexts?.contact_title}
            </div>
            <div className={'inputs-description'}>
                {staticTexts?.contact_description}
            </div>
            <div className={'name-inputs'}>
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.firstName}
                    error={error.firstName}
                    name={'firstName'}
                    placeholder={staticTexts?.contacts_page_placeholder_name}
                    label={staticTexts?.contacts_page_label_name}
                    maxLength={100}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.lastName}
                    error={error.lastName}
                    name={'lastName'}
                    placeholder={staticTexts?.contacts_page_placeholder_lastName}
                    label={staticTexts?.contacts_page_label_lastName}
                    maxLength={100}
                    onChange={getInputValues}
                />
            </div>
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={fieldsData.email}
                error={error.email}
                name={'email'}
                placeholder={staticTexts?.contacts_page_placeholder_email}
                label={staticTexts?.contacts_page_label_email}
                maxLength={100}
                onChange={getInputValues}
            />
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={fieldsData.phone}
                error={error.phone}
                name={'phone'}
                placeholder={staticTexts?.contacts_page_label_phoneNumber}
                label={staticTexts?.contacts_page_placeholder_phoneNumber}
                maxLength={100}
                onChange={getInputValues}
            />
            <InputGroup
                inputType={'textarea'}
                type={"text"}
                value={fieldsData.message}
                error={error.message}
                name={'message'}
                placeholder={staticTexts?.contacts_page_placeholder_message}
                label={staticTexts?.contacts_page_label_message}
                onChange={getInputValues}
            />
            <button className='sent-email'
                    onClick={() => {
                        if (!requestLoading && sendVisit) {
                            sendVisit()
                        }
                    }}>
                {staticTexts?.contacts_page_btn_send}
                {
                    requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                        : ''
                }

            </button>
        </div>
        <Subscribe requestLoading={requestLoading} staticTexts={staticTexts}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    SendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
