// import packages
import React from "react";
import {connect} from "react-redux";
import {Link} from 'react-router-dom'

// Import assets
import '../assets/styles/components/footer.scss'

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetContacts} from "../redux/actions";
import {Collapse, Menu} from 'antd';
import {ReactComponent as Logo} from '../assets/images/Logo.svg';
import {FooterLogoIcon} from "../assets/images";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";
import {generateFileMediaUrl, generateImageMediaUrl} from "../utils/generateMediaUrl";

// const {Panel} = Collapse;

const {Panel} = Collapse;

function Footer(props) {
    const {contacts, staticTexts,services} = props;

    function downloadPresentation(pdf) {

        if (pdf) {
            fetch(generateImageMediaUrl(pdf?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = `${pdf.name}`;
                        a.click();
                    });
                });
        }
    }

    return <footer className='footer-wrapper'>
        <div className="desktop-footer-wrapper">
            <div className="top-part">
                <div className="col">
                    <div className="logo-wrapper">
                       <Logo title={''}/>
                    </div>

                    {contacts?.presentationFile && <div className={'text-wrapper presentation-button'} onClick={() => {downloadPresentation(contacts?.presentationFile)}}>
                        {staticTexts?.footer_section_button_presentation || 'Ներբեռնեք ներկայացումը'}
                    </div>}
                </div>

                <div className={'right-block'}>
                    <div className="col">
                        <div className="title">{staticTexts?.footer_section_sites_title}</div>
                        <Link to={getUrlWithLocal(`/`)}>{staticTexts?.footer_section_home}</Link>
                        <Link to={getUrlWithLocal(`/about`)}>{staticTexts?.footer_section_about}</Link>
                        <Link to={getUrlWithLocal(`/works`)}>{staticTexts?.footer_section_news}</Link>
                        <Link to={getUrlWithLocal(`/contacts`)}>{staticTexts?.footer_section_contact}</Link>
                    </div>
                    <div className="col">
                        <div className="title">{staticTexts?.footer_section_services_title}</div>

                        {
                            services?.length && services?.map((item) => {
                                return <Link to={getUrlWithLocal(`/service/${item?.slug}`)} >
                                        <span className={'item-description'}>{item?.title}</span>
                                    </Link>
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="bottom-part">
                <div className="copyright">{staticTexts?.footer_copyright}</div>
                <div className='developed-by'>
                    <div className={'develop'}>
                        {staticTexts?.footer_section_developed_by_text}
                        <a href={"https://solidy.am/"} target={'_blank'} className={'footer-logo'}>
                            <FooterLogoIcon/>
                        </a>
                    </div>
                    <div className={'design'}>
                        {staticTexts?.footer_section_developed_by_text} <p>Ham’n’Jam</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'selectedLanguage',
        'services'
    ])
};

const mapDispatchToProps = {GetContacts};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
