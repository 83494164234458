import React from "react";

import { generateImageMediaUrl } from "../../utils/generateMediaUrl";

import "../../assets/styles/containers/servicespage/slider.scss";

export default function Slider({ block }) {
  return (
    <div className="service-slider">
      <div className="service-slider-header">
        {block?.title && <p className="service-slider-name">{block?.title}</p>}
      </div>
      <div className="service-slider-main">
        <div className="service-slider-info">
            <h4 className="service-slider-info-description" dangerouslySetInnerHTML={{ __html: block?.description}} />
        </div>
        <div className="service-slider-image">
          <div className="service-slider-image-relative">
            <img
              src={generateImageMediaUrl(block?.mediaMain?.path)}
              alt={block?.mediaMain?.name}
              title={block?.mediaMain?.name}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
