import AdvantageItem from "../uiElements/AdvantageItem/AdvantageItem";

import "../../assets/styles/containers/servicespage/advantages.scss";
import React from "react";

export default function Advantages({ block }) {
  return (
    <div className="service-advantage">
      <h2>{block?.title}</h2>
        <h4 dangerouslySetInnerHTML={{__html: block?.description}}/>
      <div className="service-advantage-items">
        {block?.options?.map((element) => (
          <AdvantageItem key={element.title} text={element.title} />
        ))}
      </div>
    </div>
  );
}
