// import packages
import React from "react";
import {connect} from "react-redux";

//Import styles
import "../../assets/styles/homepage/mainBlock.scss";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetHome} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {useEffect} from "react";

//Import Components

// Import utils


function MainBlock(props){
    const {home,staticTexts} = props

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    console.log(home,'home')
    return <div className={'mainBlock'}>
        <div className={'img-block'}>
            <div className={'img-wrapper'}>
                <img src={generateImageMediaUrl(home?.mediaMain?.path)} alt=""/>
            </div>
        </div>
        <div className={'info-block'}>
            <div className={"text"}>
                <div className={'title'}>
                    {home?.title}
                </div>
                <div className={'description'}>
                    {home?.description}
                </div>
                <Link to={getUrlWithLocal('/contacts')} className={'apply-btn'}>
                    {staticTexts?.homepage_main_block_apply}
                </Link>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'home',
    ])
};
const mapDispatchToProps = {
    GetHome,
};
export default connect(mapStateToProps, mapDispatchToProps)(MainBlock);