// Import packages
import React, { Component } from "react";
import { Route, Switch, Router} from "react-router-dom";
import { store } from "../redux/store";

// Import utils
import { history } from "../configs/history";

// Import pages
import Layout from "../containers/Layout";
import Homepage from "../containers/Homepage";
import Connect from "../containers/Contact";
import ServicesPage from "../containers/ServicesPage";
import About from "../containers/About";
import Works from "../containers/Works";

const locales = ['hy', 'en', 'ru']

export default class Routes extends Component {
    render () {
        return <Router history={history}>
            <Layout>
                <Route path={"/:locale?"} component={ChildrenRoutes}/>
            </Layout>
        </Router>
    }
}

class ChildrenRoutes extends Component {
    render () {
        const { location } = this.props
        const path = location.pathname
        const { selectedLanguage, languages } = store.getState().general || {}
        const selectedCode = selectedLanguage?.code || languages?.find(lg => lg.isMain)?.code || 'hy'
        const actualLocals = (languages && languages.length && languages.map(lg => lg.code)) || locales
        if (!path || path === '/') {
            history.push(`/${selectedCode}`)
            return null
        }
        let locale = path.split('/')[1]

        if (!actualLocals.includes(locale)) {
            const newPath = `/${selectedCode}${path}`
            history.push(newPath)
            return null
        }

        return <Switch>
            <Route exact path={`/${locale}`} component={Homepage}/>
            <Route exact path={`/${locale}/service/:slug`} component={ServicesPage}/>
            <Route exact path={`/${locale}/contacts`} component={Connect}/>
            <Route exact path={`/${locale}/about`} component={About}/>
            <Route exact path={`/${locale}/works`} component={Works}/>
        </Switch>

    }
}
