import React from "react";

import { generateImageMediaUrl } from "../../utils/generateMediaUrl";

import Vector1 from "../../assets/images/vector1.png";
import Vector1Arrow from "../../assets/images/vector1-arrow.png";
import Vector2 from "../../assets/images/vector2.png";
import Vector2Arrow from "../../assets/images/vector2-arrow.png";

import "../../assets/styles/containers/servicespage/information.scss";

export default function Information({ block,staticTexts }) {
  return (
    <div className="service-information">
      <div className="service-information-main">
        {/*<p className={'text'}>{staticTexts?.service_information_title}</p>*/}
        <h2 className="service-information-main-title">{block?.title}</h2>
        <h4 className="service-information-main-description" dangerouslySetInnerHTML={{__html: block?.description}}/>
      </div>
      <div className="service-information-images">
        {block?.options?.map((option) => {
          return (
            <div
              key={option?.title}
              className="service-information-image-block">
              <img
                src={generateImageMediaUrl(block?.mediaMain?.path)}
                alt={block?.mediaMain?.name}
                title={block?.mediaMain?.name}
              />
              <p className="service-information-image-block-text">{option?.title}</p>
            </div>
          );
        })}
        <div className="vector1-block">
          <div className="vector1-block-img">
            <img alt="vector1" className="vector1" src={Vector1} />
            <img
              alt="vector1-Arrow"
              className="vector1-arrow"
              src={Vector1Arrow}
            />
          </div>
        </div>
        <div className="vector2-block">
          <div className="vector2-block-img">
            <img alt="vector2" className="vector2" src={Vector2} />
            <img
              alt="vector2-Arrow"
              className="vector2-arrow"
              src={Vector2Arrow}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
