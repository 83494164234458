import { GENERAL_CONSTS } from "../constants";
import { history } from "../../configs/history";

export const initialState = {
    languages: [],
    selectedLanguage: null,
    staticTexts: {},
    blogs: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    lastNews: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    blogPage: {},
    partners: [],
    sponsors: [],
    home:undefined,
    countryCode: 'en',
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case GENERAL_CONSTS.GET_LANGUAGES:
            const mainLanguage = action.payload.find(lg => lg.isMain);

            let newSelectedLanguage ='';
            const pathCode = history.location?.pathname?.split('/')?.[1]
            console.log('pathCode',pathCode);
            if(pathCode && action.payload.find(lg => lg.code === pathCode)){
                newSelectedLanguage = action.payload.find(lg => lg.code === pathCode)
            }else{
                const selectedLanguageIsExist = state.selectedLanguage && state.selectedLanguage.id
                    && action.payload.find(lg => lg.id === state.selectedLanguage.id);
                newSelectedLanguage = selectedLanguageIsExist ? state.selectedLanguage :
                    (mainLanguage ? mainLanguage : action.payload[0]);
            }
            console.log('newSelectedLanguage', newSelectedLanguage)
            const newState = {
                ...state,
                languages: action.payload,
                selectedLanguage: newSelectedLanguage,
            };

            if (state.staticTexts && state.staticTexts[newSelectedLanguage.code]) {
                newState.translation = state.staticTexts[newSelectedLanguage.code]
            }

            return newState;
        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };
        case GENERAL_CONSTS.GET_STATIC_TEXTS:
            return {
                ...state,
                staticTexts: action.payload[state.selectedLanguage?.code || 'en']
            };
        case GENERAL_CONSTS.GET_BLOGS:
            return {
                ...state,
                blogs: {
                        count: action.payload.data.newsCount,
                        itemsList:action.payload.reset ? action.payload.data.news : [...state.data.blogs, ...action.payload.data.news],
                        hasMore: action.payload.hasMore
                }
            };
        case GENERAL_CONSTS.GET_PARTNERS:
            return {
                ...state,
                partners: action.payload
            };
        case GENERAL_CONSTS.GET_HOME:
            return {
                ...state,
                home: action.payload
            };
        case GENERAL_CONSTS.GET_SPONSORS:
            return {
                ...state,
                sponsors: action.payload
            };
        case GENERAL_CONSTS.GET_LAST_NEWS:
            return {
                ...state,
                lastNews:{
                    count: action.payload.data.newsCount,
                    itemsList:action.payload.reset ? action.payload.data.news : [...state.data.blogs, ...action.payload.data.news],
                    hasMore: action.payload.hasMore
                },
            };
        default:
            return state;
    }
}
