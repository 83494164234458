import React from "react";

import { generateImageMediaUrl } from "../../utils/generateMediaUrl";

import "../../assets/styles/containers/servicespage/possibility.scss";

export default function Possibility({ block }) {
  return (
    <div className="service-possibility">
      <div className="service-possibility-main">
        <h2 className="service-possibility-main-title">{block?.title}</h2>
          <h4 className="service-possibility-main-description" dangerouslySetInnerHTML={{__html: block?.description}}/>
      </div>
      <div className={`${block?.options.length > 3 ? 'service-possibility-images' : 'service-block'}`}>
        {block?.options?.map((option) => {
          return (
            <div
              key={option?.title}
              className={`service-possibility-image-block`}>
              <img
                src={generateImageMediaUrl(option?.mediaMain?.path)}
                alt={option?.mediaMain?.name}
                title={option?.mediaMain?.name}
              />
              <h5 className="service-possibility-image-block-title">{option?.title}</h5>
              <p className="service-possibility-image-block-description">{option?.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
