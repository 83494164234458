import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import "../assets/styles/containers/services.scss"

import ServiceHeader from "../components/servicespage/ServiceHeader";
import Information from "../components/servicespage/Information";
import Advantages from "../components/servicespage/Advantages";
import MainBlock from "../components/servicespage/MainBlock";
import Possibility from "../components/servicespage/Possibility";
import Types from "../components/servicespage/Types";
import Slider from "../components/servicespage/Slider";
import Sponsors from "../components/Sponsors";
import Subscribe from "../components/Subscribe";

import { getPropsFromState } from "../redux/mapStateToProps";
import { GetSelectedService } from "../redux/actions";
import { serviceComponentTypes } from "../constants/serviceComponentTypes";
import {useState} from "react";
import {LoadingIcon} from "../assets/images";
import {LoadingLinear} from "../components/uiElements/Loading";

function ServicesPage(props) {
  const { selectedService, GetSelectedService, sponsors, staticTexts } = props;
    const [loading,setLoading] = useState(false)

  const { pathname } = useLocation();
  const [, lang, , slug] = pathname.split("/");

  useEffect(() => {
      setLoading(true)
    GetSelectedService(slug).finally(() => {
        setLoading(false)
    });
    window.scrollTo(0, 0);
  }, [lang, slug,props.selectedLanguage?.code]);

  return (
    <div className="service_page-wrapper">
        {
            !loading ?  <div className={"service_page_blocks"}>
                <ServiceHeader selectedService={selectedService} staticTexts={staticTexts}/>
                {selectedService?.blocks?.map((block) => {
                    switch (block.type) {
                        case serviceComponentTypes.service_information:
                            return (
                                <Information
                                    key={block?.id}
                                    block={block}
                                    staticTexts={staticTexts}
                                />
                            );
                        case serviceComponentTypes.service_advantages:
                            return <Advantages key={block?.id} block={block} />;
                        case serviceComponentTypes.service_main_block:
                            return (
                                <MainBlock
                                    key={block?.id}
                                    block={block}
                                    staticTexts={staticTexts}
                                />
                            );
                        case serviceComponentTypes.service_possibility:
                            return <Possibility key={block?.id} block={block} />;
                        case serviceComponentTypes.service_types:
                            return <Types key={block?.id} block={block} />;
                        case serviceComponentTypes.service_slider:
                            return (
                                <Slider
                                    key={block?.id}
                                    block={block}
                                    style={{ backgroundColor: block?.backgroundColor }}
                                />
                            );
                        default:
                            return null;
                    }
                })}
                <Sponsors sponsors={sponsors} staticTexts={staticTexts} />
            </div> : <div className={'loading-wrapper'}>
              <LoadingLinear/>
            </div>
        }
      <Subscribe staticTexts={staticTexts} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return getPropsFromState(state, [
    "selectedService",
    "sponsors",
    "staticTexts",
  ]);
};

const mapDispatchToProps = {
  GetSelectedService,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPage);
