import React from "react";

import CheckMarkIcon from "../../../assets/images/tickcircle.jpg";

import "./AdvantageItem.scss";

export default function AdvantageItem({ text }) {
  return (
    <div className="advantage-item">
      <img src={CheckMarkIcon} alt="checked"/>
      <p className="advantage-item-text">{text}</p>
    </div>
  );
}
